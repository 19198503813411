<template>
  <!-- <LoaderAnimation v-if="loading" /> -->
  <div v-if="filteredResults.length > 0" id="search">
    <div v-for="result in filteredResults" :key="result.id" class="result">
      <img :src="result.backdrop_path" :alt="result.name || result.title" />
      <div class="overlay"></div>
      <h2 class="result-name">{{ result.name || result.title }}</h2>
      <p class="result-type">
        {{ formatMediaType(result.media_type) }}
      </p>
      <div class="result-description">
        <p>{{ truncateText(result.overview) }}</p>
        <router-link
          :to="{ name: 'PDF', params: { playId: result.id, play: result } }"
          class="btn"
          >Read More</router-link
        >
      </div>
    </div>
  </div>
  <NoMatch v-else />
</template>

<script>
import { NoMatch } from "@/components";
import { truncateText, formatMediaType } from "@/utils";
import { mapGetters } from "vuex";

export default {
  name: "Search",
  components: {
    // LoaderAnimation,
    NoMatch,
  },
  computed: {
    ...mapGetters(["plays", "query"]),
    filteredResults() {
      return this.query
        ? this.plays.filter((play) =>
            play.name.toLowerCase().includes(this.query.toLowerCase())
          )
        : [];
    },
  },
  methods: {
    truncateText,
    formatMediaType,
  },
};
</script>

<style lang="scss" scoped>
#search {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 5px;
  min-height: calc(100vh - 70px);
  padding: 4vw;
  color: #fff;

  .result {
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background: black;
      opacity: 0;
      top: 0;
      left: 0;
      transition: 0.4s opacity ease;
    }

    &-name {
      position: absolute;
      top: 10px;
      /* width: 100%; */
      max-height: 50%;
      transform-origin: bottom left;
      /* transform: rotate(-90deg); */
      writing-mode: vertical-rl;
      font-weight: 400;
      transition: 0.4s all ease-in-out;
      left: -30%;
    }

    &-type {
      border: 2px solid white;
      padding: 2px 5px;
      text-transform: capitalize;
      transition: 0.4s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: absolute;
      top: -100px;
      right: 10px;
      font-size: 1.2rem;
    }

    &-description {
      padding: 10px;
      text-transform: capitalize;
      transition: 0.4s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
      position: absolute;
      bottom: -50%;
      right: 0;
      width: 100%;
      font-size: 1.2rem;

      .btn {
        all: unset;
        cursor: pointer;
        background: #e5e5e5;
        color: #3c4043;
        border-radius: 30px;
        /* font-size: 1.4rem; */
        padding: 10px 20px;
        display: inline-block;
        font-weight: 600;
        margin-top: 15px;
        transition: 0.2s all ease-in;

        &:hover {
          background: #3c4043;
          color: #e5e5e5;
        }
      }
    }

    &:hover {
      .overlay {
        opacity: 0.8;
      }

      .result-name {
        left: 10px;
      }

      .result-type {
        top: 10px;
      }

      .result-description {
        bottom: 0;
      }
    }
  }
}

#no-match {
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
